import help from "./../assets/img/help.svg";
import coins from "./../assets/img/coins.svg";
import compta from "./../assets/img/compta.svg";
import eye from "./../assets/img/switch.svg";
import calendar from "./../assets/img/calendar.svg";
import folder from "./../assets/img/SYNTHESE.svg";
import objectif from "./../assets/img/BUDGET.svg";
import logoIcon from "./../assets/img/logoIcon.svg";
import pointed from "./../assets/img/pointedStatus.svg";
import engaged from "./../assets/img/engagedStatus.svg";
import prev from "./../assets/img/prevStatus.svg";
import real from "./../assets/img/realStatus.svg";
import simul from "./../assets/img/simulStatus.svg";
import graphic from "./../assets/img/graphic.svg";
import releve from "./../assets/img/relevé.svg";
import category from "./../assets/img/categories.svg";

export const TRESO_ELEMENTS = [
  {
    id: 1,
    label: "Pointé",
    status: "pointé",
    filterValue: { status: "pointé" },
    backgroundColor: "#fffa7d",
    secondLabel: "Solde pointé",
    signature: "(ecr. pointées)",
    icon: pointed,
    type: "status",
  },
  {
    id: 2,
    label: "En cours",
    status: "engagé",
    filterValue: { status: "engagé,pointé" },
    backgroundColor: "#FFFDBF",
    secondLabel: "Solde à venir",
    signature: "(ecr. pointées + en cours)",
    icon: engaged,
    type: "status",
  },
  {
    id: 3,
    label: "Prév",
    status: "prévisionnel",
    filterValue: {
      type: "réel",
      status: "prévisionnel,engagé,pointé",
    },
    secondLabel: "Solde prévisionnel",
    signature: "(toutes ecr. hors simulation)",
    backgroundColor: "#FFFFD9",
    icon: prev,
    type: "status",
  },
  {
    id: 4,
    label: "Simul",
    status: "simulation",
    filterValue: {
      type: "simulation,réel",
      status: "prévisionnel,engagé,pointé",
    },
    secondLabel: " Solde prévisionnel avec simulation",
    signature: "(toutes les écr.)",
    backgroundColor: "#ffffff",
    icon: simul,
    type: "type",
  },
];

export const COMPTA_ELEMENTS = [
  // {
  //   id: 2,
  //   label: "(Réelle) Soldée",
  //   status: "réel",
  //   filterValue: {
  //     type: "réel",
  //     status: "pointé",
  //   },
  //   backgroundColor: "rgba(170, 239, 230, 0.9019607843)",
  //   secondLabel: "Total",
  //   signature: "(avec écritures de simulation)",
  //   type: "type",
  //   icon: simul,
  // },
  {
    id: 1,
    label: "Réelle ",
    status: "réel",
    filterValue: {
      type: "réel",
    },
    backgroundColor: "#E6FBF8",
    secondLabel: "Total",
    signature: "(écritures réelles seules)",
    icon: real,
    type: "type",
  },

  {
    id: 3,
    label: "Simul",
    status: "simulation",
    filterValue: {
      type: "simulation,réel",
    },
    backgroundColor: "#ffffff",
    secondLabel: "Total",
    signature: "(avec écritures de simulation)",
    type: "type",
    icon: simul,
  },
];

export const SIDEBAR_LIST = [
  {
    id: 0,
    name: "Trésorerie",
    items: [
      {
        id: 0,
        name: "Trésorerie",
        icon: coins,
        background: "#AAEFE6",
        border: "#C9AAEF",
      },
      {
        id: 1,
        name: "Comptabilité",
        icon: compta,
        background: "#C9AAEF",
        border: "#AAEFE6",
      },
    ],
    icon: logoIcon,
    label: "Mode",
    navButton: true,
    isSwitch: true,
  },
  {
    id: 1,
    icon: eye,
    label: "Vue",
    isPopover: true,
    items: [
      { id: 0, name: "Catégories", icon: category },
      {
        id: 1,
        name: "Relevé",
        icon: releve,
        route: "listing",
      },
      { id: 2, name: "Graphique", icon: graphic, route: "graph" },
    ],
  },
  {
    id: 2,
    icon: calendar,
    name: "Peride affichée",
    isDatePicker: true,
    hasDivider: true,
  },
  {
    id: 3,
    name: "Afficher la synthèse",
    value: "synthesis",
    icon: folder,
  },
  {
    id: 4,
    name: "Afficher un budget prev.",
    value: "objectif",
    icon: objectif,
    background: "#FFFFFF",
    hasDivider: true,
  },
  {
    id: 5,
    name: "Besoin d’aide ?",
    value: "help",
    icon: help,
  },
];
export const WRITING_TYPES = [
  {
    id: "2",
    value: "réel",
    label: "Réelle",
    icon: real,
    backgroundColor: "#E6FBF8",
  },
  {
    id: "1",
    value: "simulation",
    label: "Simul",
    icon: simul,
    backgroundColor: "#F8F8F8",
  },
];

export const ONLY_SIMUL = [
  {
    id: "1",
    value: "simulation",
    label: "Simul",
    icon: simul,
    backgroundColor: "#F8F8F8",
  },
];

export const TRESORY_STATUS = [
  {
    id: "1",
    value: "pointé",
    label: "Pointé",
    icon: pointed,
    backgroundColor: "#fffa7d",
  },
  {
    id: "2",
    value: "engagé",
    label: "En cours",
    icon: engaged,
    backgroundColor: "#FFFDBF",
  },
  {
    id: "1",
    value: "prévisionnel",
    label: "Prev",
    icon: prev,
    backgroundColor: "#FFFFD9",
  },
];

export const REGROUPMENT_ELEMENTS = [
  {
    id: 1,
    label: "Mois par Mois",
    value: 1,
    backgroundColor: "white",
  },
  {
    id: 2,
    label: "Regroupement par 2 Mois",
    value: 2,
    backgroundColor: "white",
  },
  {
    id: 3,
    label: "Regroupement par 3 Mois",
    value: 3,
    backgroundColor: "white",
  },
  {
    id: 4,
    label: "Regroupement par 4 Mois",
    value: 4,
    backgroundColor: "white",
  },
  {
    id: 6,
    label: "Regroupement par 6 Mois",
    value: 6,
    backgroundColor: "white",
  },
  {
    id: 7,
    label: "Regroupement par année",
    value: 12,
    backgroundColor: "white",
  },
];
export const DUPLICATION_TYPE = [
  {
    id: "1",
    value: "Tous les mois",
    label: "Tous les mois",
  },
  {
    id: "2",
    value: "Tous les 2 mois",
    label: "Tous les 2 mois",
  },
  {
    id: "3",
    value: "Tous les 3 mois",
    label: "Tous les 3 mois",
  },
  {
    id: "4",
    value: "Tous les 6 mois",
    label: "Tous les 6 mois",
  },
  {
    id: "5",
    value: "Tous les ans",
    label: "Tous les ans",
  },
];
export const DUPLICATE_PERIOD_TRESO = [
  {
    id: "1",
    value: 2,
    label: "=Date Tréso -2 mois",
  },
  {
    id: "2",
    value: 1,
    label: "=Date Tréso -1 mois",
  },
  {
    id: "0",
    value: 0,
    label: "=Date Tréso",
  },
];
export const DUPLICATE_PERIOD_COMPTA = [
  {
    id: "1",
    value: 2,
    label: "=Date d'engagement +2 mois",
  },
  {
    id: "2",
    value: 1,
    label: "=Date d'engagement +1 mois",
  },
  {
    id: "3",
    value: 0,
    label: "=Date d'engagement",
  },
];
export const DECALAGE_SERIE = [
  {
    id: "0",
    value: -3,
    label: "-3 mois",
  },
  {
    id: "1",
    value: -2,
    label: "-2 mois",
  },
  {
    id: "2",
    value: -1,
    label: "-1 mois",
  },
  {
    id: "3",
    value: 0,
    label: "Ne pas décaler",
  },
  {
    id: "4",
    value: 1,
    label: "+1 mois",
  },
  {
    id: "5",
    value: 2,
    label: "+2 mois",
  },
  {
    id: "6",
    value: 3,
    label: "+3 mois",
  },
];

export const colors = [
  {
    key: "Violet",
    value: "Violet",
    label: "Violet",
    color: "#e7adff",
  },
  {
    key: "Gris",
    value: "Gris",
    label: "Gris",
    color: "#c0c0c0",
  },
  {
    key: "Beige",
    value: "Beige",
    label: "Beige",
    color: "#d7ac7b",
  },
  {
    key: "Bleu",
    value: "Bleu",
    label: "Bleu",
    color: "#4096ff",
  },
  {
    key: "Rose",
    value: "Rose",
    label: "Rose",
    color: "#c2185b",
  },
  {
    key: "Vert",
    value: "Vert",
    label: "Vert",
    color: "#c0ca33",
  },
  {
    key: "Orange",
    value: "Orange",
    label: "Orange",
    color: "#ff8f00",
  },
];
