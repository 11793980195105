import React from "react";
import { NavLink } from "react-router-dom";
import SwitchMode from "./SwitchMode";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

function CustomNavLink({
  route,
  isActive,
  onClick,
  name,
  label,
  icon,
  isSubNav,
  background,
  isSwitch,
  subItems,
  modeButton,
  className,
}) {
  const { isSidebarClosed } = useSelector((state) => state.layout);

  let newClassName;
  if (className) {
    newClassName = className;
  } else if (isActive) {
    newClassName = `active ${
      modeButton ? "mode-button " : "button border-category"
    }`;
  } else {
    newClassName = `${modeButton ? "mode-button " : "button border-category"}${
      isSubNav ? "subnav-button" : ""
    }`;
  }

  if (isSwitch && subItems?.length === 2) {
    return (
      <NavLink
        to={route}
        type="button"
        onClick={() => onClick(name)}
        style={{ backgroundColor: background }}
        className={newClassName}
      >
        <Tooltip
          title={isSidebarClosed ? "Switch Tréso / Résultat" : null}
          mouseLeaveDelay={0}
          mouseEnterDelay={0.8}
          trigger="hover"
        >
          <div className="button-switch-container">
            <span className="button-switch-text">Résultat</span>
            <div className="button-group">
              <SwitchMode />
            </div>
            <span className="button-switch-text">Trésorerie</span>
          </div>
        </Tooltip>
      </NavLink>
    );
  }

  return (
    <>
      <NavLink
        to={route}
        type="button"
        onClick={() => onClick(name)}
        style={{ backgroundColor: background }}
        className={newClassName}
      >
        <img
          className="nav-img"
          src={icon}
          alt="nav"
          style={{ filter: "brightness(100%) invert(1)" }}
        />
        <span className="hidden-element">{label}</span>

        {isSwitch && subItems?.length === 2 ? (
          <div className="button-group">
            <SwitchMode />
          </div>
        ) : (
          <div className="text-container">
            <span className="hidden-element-text">{name}</span>
          </div>
        )}
      </NavLink>
    </>
  );
}

export default CustomNavLink;
