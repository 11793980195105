import { CloseOutlined } from "@ant-design/icons";
import { Popover, Radio } from "antd";
import isEqual from "lodash/isEqual";
import React from "react";
import ElementTypeStatus from "../ElementTypeStatus";
import "./index.scss";

export const filterData = [
  {
    id: 2,
    label: "Réelle",
    children: [
      {
        id: 21,
        secondLabel: "soldée",
        backgroundColor: "#a9efe5",
        filterValues: { status: "pointé", type: "réel" },
      },
      {
        id: 22,
        secondLabel: "non-soldée",
        backgroundColor: "rgb(204 236 255)",
        filterValues: { status: "engagé,prévisionnel", type: "réel" },
      },
    ],
  },
  {
    id: 3,
    label: "simul",
    children: [
      {
        id: 21,
        secondLabel: "simulation",
        backgroundColor: "rgb(246 246 246)",
        filterValues: { type: "simulation" },
      },
    ],
  },
];

export const footerData = [
  {
    id: 21,
    signature: "(écritures réelles seules)",
    secondLabel: "Total",
    backgroundColor: "rgb(230, 251, 248)",
    filterValues: { status: "engagé,prévisionnel,pointé", type: "réel" },
  },
  {
    id: 23,
    secondLabel: "Total",
    signature: "(avec écritures de simulation)",
    backgroundColor: "rgb(246 246 246)",
    filterValues: {
      status: "engagé,prévisionnel,pointé",
      type: "réel,simulation",
    },
  },
];

const ComptaFilterTable = ({
  filtredMode,
  menuRef,
  onClose,
  shouldDisable,
  popoverClassName,
  onChange,
  onClickOnOption,
  value,
  toggleNoFilterCahnge,
  isGraphFilter,
  type,
}) => {
  return (
    <Popover
      placement="top"
      className={`popover-filter-table ${popoverClassName}`}
    >
      <CloseOutlined
        style={{
          fontSize: "20px",
          position: "absolute",
          top: "1.5rem",
          cursor: "pointer",
          right: "2rem",
          width: "15px",
          height: "15px",
        }}
        onClick={onClose}
      />

      <div className="change-type" ref={menuRef}>
        <div className="arrow"></div>

        <div style={{ maxHeight: "350px" }}>
          {footerData.map((filter) => {
            const areObjectsEqual = isEqual(value?.[0], filter.filterValues);

            return (
              <div key={filter.id}>
                <Radio
                  onChange={(e) => {
                    onChange(e, filter.filterValues);
                    onClickOnOption(filter);
                  }}
                  className="checkbox-filter"
                  value={filter.filterValues}
                  checked={areObjectsEqual}
                >
                  <ElementTypeStatus
                    checkedElementMode={filter}
                    elementsTypesClass="elements-types"
                    smallSignatureClass="small-signature"
                  />
                </Radio>
              </div>
            );
          })}
          {isGraphFilter && (
            <Radio
              onChange={toggleNoFilterCahnge}
              className="checkbox-filter"
              checked={!value}
            >
              <div className="no-filter-checkbox-option">
                {type === "compta" ? "Aucun total" : "Aucun solde"}
              </div>
            </Radio>
          )}
        </div>
      </div>
    </Popover>
  );
};

export default ComptaFilterTable;
