import { DatePicker, Tooltip } from "antd";
import fr_FR from "antd/lib/date-picker/locale/fr_FR";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { ReactComponent as NextIcon } from "../../assets/img/icons/next.svg";
import { ReactComponent as PrevIcon } from "../../assets/img/icons/prev.svg";
import rangePicker from "../../assets/img/CALENDRIER.svg";
import { adjustCalendarYears } from "../../utilities/adjustCalendarYears";
import { formatMonthRange } from "../../utilities/filters";

let selectedMonth = [null, null];

const CustomRangePicker = ({
  className,
  selectedMonthRange,
  handleMonthChange,
  isSyntheseSelected,
  label,
  index,
}) => {
  const customCellRender = (value, e) => {
    const formattedDate = dayjs(value)
      .format("MMM")
      .replace(/\./g, "")
      .slice(0, 4);

    return <div className="ant-picker-cell-inner">{formattedDate}</div>;
  };

  useEffect(() => {
    selectedMonth[index] = selectedMonthRange;
  }, [selectedMonthRange]);

  const renderFooter = () => (
    <Tooltip
      mouseLeaveDelay={0}
      title="Sélectionnez d'abord le mois de début de la période, puis le mois de fin 
"
    >
      <div
        style={{
          textAlign: "center",
          width: "25px",
          height: "25px",
          border: "1px solid grey",
          borderRadius: "50%",
          fontWeight: "bold",
          color: "grey",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "ui-serif",
        }}
      >
        i
      </div>
    </Tooltip>
  );

  return (
    <>
      <DatePicker.RangePicker
        value={selectedMonth[index]}
        onCalendarChange={(selectedDates, info) => {
          selectedMonth[index] = selectedDates;
          const [startDate] = selectedDates;
          const year = startDate.year();
          const firstYear = document
            .querySelector(
              `.ant-picker-dropdown:not(.ant-picker-dropdown-hidden)`
            )
            .querySelector(".ant-picker-year-btn");

          adjustCalendarYears(year, Number(firstYear.textContent) === year);
        }}
        onOpenChange={(open) => {
          if (!open) {
            if (selectedMonth[index][1] !== null)
              handleMonthChange(selectedMonth[index]);
          }
        }}
        renderExtraFooter={renderFooter}
        format={"MM"}
        separator={null}
        locale={fr_FR}
        cellRender={customCellRender}
        picker="month"
        superNextIcon={<NextIcon />}
        superPrevIcon={<PrevIcon />}
        dropdownClassName="custom-RangePicker-dropdown"
        allowClear={false}
        className={`${className} x-${index}`}
        suffixIcon={
          isSyntheseSelected ? (
            <span id="input-label-synthese">
              {formatMonthRange(selectedMonthRange)}
            </span>
          ) : (
            <div className="range-picker-button-wrapper">
              <img src={rangePicker} alt="Range Picker Icon" id="range-id" />

              <span className="input-label-range-picker">
                <p style={{ width: "30px" }}>{label}</p>
                <p className="input-label-synthese">
                  {formatMonthRange(selectedMonthRange)}
                </p>
              </span>
            </div>
          )
        }
      />
    </>
  );
};

export default CustomRangePicker;
