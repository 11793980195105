import React from "react";
import Button from "../../Button";
import Overlay from "../../Overlay";
import HeaderOverlay from "../../Project/Header";
import Title from "../../Project/Title";

function DndHorizontalCheck({ open, id, handleClose, data }) {
  const handleCloseFunc = (id) => {
    handleClose(id);
    data.abandon();
  };

  return (
    <Overlay show={open} closable={true} onClick={handleCloseFunc}>
      <HeaderOverlay showlogo>
        <Title
          isDelete
          projectTitle={
            <div style={{ width: "95%" }}>
              {data?.mode === "compta"
                ? `Vous essayez de déplacer une écriture "réelle" ou "soldée" vers une nouvelle période. Ce déplacement n'est pas permis via le "glisser / déplacer ", car la date d'engagement d'une écriture de type "réel" n'est pas censée évoluer! Vous pouvez toutefois modifier la date d'engagement de l'écriture. Que souhaitez-vous faire ? `
                : ` Vous essayez de déplacer une écriture "pointée" vers une nouvelle
              période. Ce déplacement n'est pas permis via le "glisser /
              déplacer ", car une écriture de statut "pointé" n'est pas censée
              être déplacée! Vous pouvez toutefois modifier la date de
              trésorerie de l'écriture. Que souhaitez-vous faire ?`}
            </div>
          }
          subTitle={`Alerte déplacement !`}
        />
        <div className="verify-inputs-popup">
          <Button
            text={"Annuler"}
            onClick={() => handleCloseFunc(id)}
            className={"action-button"}
            style={{ width: "200px" }}
          />
          <Button
            text={"Modifier l'écriture "}
            onClick={() => data.action()}
            className={"action-button agree-action-button"}
            iconClassName={"delete-icon"}
            style={{ width: "200px" }}
          />
        </div>
      </HeaderOverlay>
    </Overlay>
  );
}

export default DndHorizontalCheck;
