import React from "react";

const ElementTypeStatus = ({
  smallSignatureClass,
  elementsTypesClass,
  checkedElementMode,
  account,
}) => {
  return (
    <div
      className={elementsTypesClass}
      style={{ background: checkedElementMode?.backgroundColor }}
    >
      {account && (
        <span
          className="compte-displayer-filter"
          style={{ backgroundColor: account?.color }}
        >
          {account?.code}
        </span>
      )}
      <div
        style={{
          display: "flex",
          "align-items": "center",
        }}
      >
        {checkedElementMode?.secondLabel}
      </div>
      <div className={smallSignatureClass}>{checkedElementMode?.signature}</div>
    </div>
  );
};

export default ElementTypeStatus;
