import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Popover, Button, Tooltip } from "antd";
import MenuButton from "./MenuButton";
import { setSubMenuName } from "../../../data/slices/layout";
import useClickOutside from "../../../hooks/useCloseOutside";
import chevron from "../../../assets/img/icons/table/Vector.svg";

const MenuPopover = ({
  isActive,
  label,
  icon,
  isSubNav,
  background,
  subItems,
}) => {
  const { id } = useParams();
  const popoverRef = useRef(null);
  const dispatch = useDispatch();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const location = useLocation();
  const { mode, nameSubMenu, isSidebarClosed } = useSelector(
    (state) => state.layout
  );

  const navigate = useNavigate();

  useEffect(() => {
    const itemToUse =
      subItems.find((el) => location.pathname.includes(el.route)) ||
      subItems[0];
    dispatch(setSubMenuName(itemToUse));
  }, [subItems, location.pathname]);

  const handleSubmitButtonState = (e, url, name) => {
    const finalUrl = url ? `/project/${id}/${url}` : `/project/${id}`;
    if (location.pathname === finalUrl) return setPopoverVisible(false);
    e.preventDefault();
    navigate(finalUrl);
    dispatch(setSubMenuName(name));
    setPopoverVisible(false);
  };

  useClickOutside([popoverRef], () => setPopoverVisible(false));

  const content = (
    <div className="button-types" ref={popoverRef}>
      {subItems?.map((item) => {
        return (
          <MenuButton
            key={item?.id}
            name={item?.name}
            handleSubmit={(e) => handleSubmitButtonState(e, item?.route, item)}
            className="button nav-link"
            primaryIcon={item?.icon}
          />
        );
      })}
    </div>
  );

  return (
    <Popover
      color={mode === "treso" ? "#fffa7d" : "#a9efe5"}
      placement="left"
      content={content}
      visible={popoverVisible}
      borderRadiusLG={20}
      borderRadiusOuter={20}
      borderRadiusXS={20}
      onClick={(visible) => setPopoverVisible(!popoverVisible)}
    >
      <Tooltip
        mouseLeaveDelay={0}
        mouseEnterDelay={0.8}
        title={isSidebarClosed ? "Vue" : null}
      >
        <Button
          bordered={false}
          style={{
            backgroundColor: background,
            borderColor: "#E9E9E9",
            transition: "unset",
          }}
          className={
            isActive
              ? "active button border-category button-menu"
              : `button border-category button-menu ${
                  isSubNav && "subnav-button"
                }`
          }
        >
          <img className="nav-img" src={nameSubMenu.icon} />

          <div className="text-container">
            <div className="hidden-element-text">Vue {nameSubMenu.name} </div>
            <img className="nav-img-chevron" src={chevron} />
          </div>
        </Button>
      </Tooltip>
    </Popover>
  );
};

export default MenuPopover;
